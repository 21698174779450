import Phaser from 'phaser'
import { createLinkAtlases } from '../anims/LinkAnims';
import { createEffectsAnims } from '../anims/EffectsAnims';
import { SceneEvents } from '../events/SceneEvents';
import ComponentService from '../services/ComponentService';
import ServerService from '../services/ServerService';
import { createWeaponAnims } from '../anims/WeaponAnims';
import { createItemAnims } from '../anims/ItemAnims';
import { createBulletAnims } from '../anims/BulletAnims';
import { createBombsAnims } from '../anims/BombAnims';
import { getHint, getMapByGameId } from '../utils/Utils';
import { createNPCAnims } from '../anims/NPCAnims';
import { createMapObjectsAnims } from '../anims/MapObjectsAnims';
import { createEnemyAnims } from '../anims/EnemyAnims';
import { GameWebMonetization } from '../plugins/GameWebMonetization/GameWebMonetization';


/**
 * Bootstrap scene that runs globally and manages sub scenes, such as game, hud, and loading screens.
 */
export default class Bootstrap extends Phaser.Scene {

    //global instances of server and component servers.
    componentService: ComponentService;
    serverService: ServerService;

    hasExtensionInstalled: boolean = false;
    gameWebMonetization: GameWebMonetization;


    /**
     * Constructs the bootstrap scene and instantiates services that will be injected into child scenes.
     */
	constructor() {
        super('bootstrap');

        this.componentService = new ComponentService();
        this.serverService = new ServerService();

        this.gameWebMonetization = new GameWebMonetization({
            paymentPointer: '$ilp.uphold.com/NLRLypJYj7pR'
        });

        //listen for pending so we know if the extension is installed
        this.gameWebMonetization.on('pending', (e) => {
            this.hasExtensionInstalled = true;
        });

        this.gameWebMonetization.start();
    }


    /**
     * Creates the bootstrap scene and general game objects.
     * Connects scene events for gmaeready
     */
    create() {

        //this.onPlay(4);
        this.onInit();

        //create general game resources
        createLinkAtlases(this);
        createEffectsAnims(this.anims);
        createWeaponAnims(this.anims);
        createItemAnims(this.anims);
        createBulletAnims(this.anims);
        createBombsAnims(this.anims);
        createNPCAnims(this.anims);
        createEnemyAnims(this.anims);
        createMapObjectsAnims(this.anims);
    }

    private startGame = (gameId: number) => {

        this.scene.launch('loading', {
            defaultState: 'Firing Up',
            hint: getHint(),
            map: getMapByGameId(gameId)
        });

        this.scene.launch("game", {
            gameMode: gameId,
            serverService: this.serverService,
            componentService: this.componentService,
            onPlayAgain: this.onPlayAgain,
            onReturnToLobby: this.onReturnToLobby,
            onGameError: this.onGameError
        });
        this.scene.launch('hud');

        //when the game emits its ready, stop the loading scene
        SceneEvents.once('ongameready', () => {
            this.scene.stop('loading');
        });
    }

    private onInit = () => {

        //start auth scene
        this.scene.launch('auth', {
            onAuthStart: this.onAuthStart,
            onSuccess: this.onSuccessAuth
        });
    }

    private onAuthStart = () => {
        this.scene.launch('loading', {
            defaultState: 'Loading Player Data...',
            hint: getHint()
        });
    };

    private onSuccessAuth = () => {
        
        //stop auth scene and launch lobby
        this.scene.stop('auth');
        this.scene.stop('loading');
        this.scene.launch('lobby', {
            onPlay: this.onPlay,
            onSignOutStart: this.onSignOutStart,
            onSignOut: this.onSignOut
        });
    }

    private onPlay = (gameId: number) => {
        
        this.scene.stop('lobby');
        this.startGame(gameId);
    }

    private onPlayAgain = (gameId: number) => {

        this.scene.stop('game');
        this.scene.stop('hud');

        this.scene.launch('loading', {
            defaultState: 'Reading up...',
            hint: getHint()
        });

        SceneEvents.once('datasaved', () => {

            this.scene.stop('loading');
            this.startGame(gameId);
        });
    }

    private onReturnToLobby = () => {

        this.scene.stop('game');
        this.scene.stop('hud');

        this.scene.launch('loading', {
            defaultState: 'Returning to Lobby',
            hint: getHint()
        });

        SceneEvents.once('datasaved', () => {

            this.scene.stop('loading');
            this.scene.launch('lobby', {
                onPlay: this.onPlay,
                onSignOutStart: this.onSignOutStart,
                onSignOut: this.onSignOut
            });
        });
    }

    private onSignOutStart = () => {

        this.scene.launch('loading', {
            defaultState: 'Signing out...',
            hint: getHint()
        });
    };

    private onSignOut = () => {

        //stop lobby and go back to auth
        this.scene.stop('lobby');
        this.scene.stop('loading');
        this.scene.launch('auth');
    }

    private onGameError = () => {
        this.serverService.leave();
        
        //stop game and hud scenes
        this.scene.stop('game');
        this.scene.stop('hud');
        this.scene.start('error', {
            message: 'Error encountered...'
        });
    }
}
