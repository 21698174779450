import 'babel-polyfill';
import * as Phaser from 'phaser';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Firestore } from 'firebase/firestore';
import { getAuth, Auth, signInWithEmailAndPassword, Unsubscribe, onAuthStateChanged, signInAnonymously, User, signOut, GoogleAuthProvider, signInWithPopup, OAuthCredential, FacebookAuthProvider, TwitterAuthProvider, AuthProvider, OAuthProvider } from 'firebase/auth';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBi_otCYyHu_XV6MFTYuxDBv1AYontnPHk",
  authDomain: "opensource-battleroyale.firebaseapp.com",
  projectId: "opensource-battleroyale",
  storageBucket: "opensource-battleroyale.appspot.com",
  messagingSenderId: "94269384220",
  appId: "1:94269384220:web:b15893be084ef721ad21d9",
  measurementId: "G-9L3Q1VT7PS"
};

export default class FirebasePlugin extends Phaser.Plugins.BasePlugin {

    private readonly db: Firestore;
    private readonly functions: Functions;
    private readonly auth: Auth;

    private authStateChangedUnsubscribe: Unsubscribe;
    private onLoggedInCallback?: () => void;

    //results cached
    private details: any;
    private topTep: any;

    constructor(manager: Phaser.Plugins.PluginManager) {
        super(manager);

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        this.db = getFirestore(app);
        this.functions = getFunctions(app);
        this.auth = getAuth(app);

        this.authStateChangedUnsubscribe = onAuthStateChanged(this.auth, (user) => {

            if(user && this.onLoggedInCallback) {
                this.onLoggedInCallback();
            }
        });
    }

    destroy() {

        this.authStateChangedUnsubscribe();
        super.destroy();
    }

    
    getUser() {
        return this.auth.currentUser;
    }

    async getUserDetails(refresh: boolean) {

        if(refresh) {

            const getData = httpsCallable(this.functions, 'getUserData');
            this.details = (await getData()).data
        }

        return this.details;
    }

    async updateUser(displayName: string) {

        const updateHandle = httpsCallable(this.functions, 'updateHandle');
        await updateHandle({
            name: displayName
        });
    }
    
    async getTopTen(refresh: boolean) {

        if(refresh) {

            const getTopTen = httpsCallable(this.functions, 'getTopTen');
            this.topTep =  (await getTopTen()).data;
        }

        return this.topTep;
    }

    signInUserWithGoogle(cb: (user: User | OAuthCredential) => void) {
        this.signInUserWithProvide(GoogleAuthProvider, cb);
    }

    signInUserWithFacebook(cb: (user: User | OAuthCredential) => void) {
        this.signInUserWithProvide(FacebookAuthProvider, cb);
    }

    signInUserWithTwitter(cb: (user: User | OAuthCredential) => void) {
        this.signInUserWithProvide(TwitterAuthProvider, cb);
    }

    signInUserWithProvide(provType: typeof GoogleAuthProvider | typeof TwitterAuthProvider | typeof FacebookAuthProvider, cb: (user: User | OAuthCredential) => void) {

        const provider = new provType();
        const auth = getAuth();

        provider.addScope('email');

        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = provType.credentialFromResult(result);
            const token = credential?.accessToken;
            const user = result.user;
            cb(user);

        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = provType.credentialFromError(error);
            console.error(errorCode)
            
            if(errorCode == 'auth/account-exists-with-different-credential') {
                alert('You have already registered with a different provider')
            }
            else {
                alert('Error: Try again')
            }
        });
    }

    async signOut() {
        await signOut(this.auth)
    }
}
