import Phaser from 'phaser'
import Bootstrap from './scenes/Bootstrap'
import GameScene from './scenes/GameScene'
import HUD from './scenes/HUD'
import LoadingScene from './scenes/LoadingScene'
import Preloader from './scenes/Preloader'
import AuthenticationScene from './scenes/AuthenticationScene'
import ErrorScene from './scenes/ErrorScene'
import LobbyScene from './scenes/LobbyScene'
import FirebasePlugin from './plugins/FirebasePlugin'

const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
	banner: false,
	width: 400,
	height: 300,
	dom: {
        createContainer: true
    },
	physics: {
		default: 'arcade',
		arcade: {
			tileBias: 8,
			gravity: { y: 0 },
			debug: false,
			fixedStep: false
		},
	},
	scale: {
		mode: Phaser.Scale.FIT,
        parent: 'gameDiv',
        autoCenter: Phaser.Scale.CENTER_BOTH,
		zoom: 2
	},
	scene: [Preloader, AuthenticationScene, LobbyScene, LoadingScene, Bootstrap, GameScene, HUD, ErrorScene],
	plugins: {
		global: [
			{
				key: 'FirebasePlugin',
				plugin: FirebasePlugin,
				start: true,
				mapping: 'firebase'
			}
		]
	}
}

export default new Phaser.Game(config)
